import { IDatasetProps } from "../models/dataset-props";

const urlParams = ["dataset", "previewLayer", "timestamp", "elevation"]

export const getDatasetProps = (url: URL) => {
    if (!(url instanceof URL)) {
        throw Error("Not an instance of URL");
    }

    if (!process.env.REACT_APP_GEOSPATIAL_SERVER_URL) {
        throw Error("REACT_APP_GEOSPATIAL_SERVER_URL is not set");
    }

    const params = url.searchParams;
    const props = {} as IDatasetProps;

    urlParams.forEach((param) => {
        if (params?.has(param)) {
            (props as any)[param] = params.get(param) as string
        }
    });

    props.geospatialServerUrl = process.env.REACT_APP_GEOSPATIAL_SERVER_URL as string;

    return props;
}
