import { store } from '@opengeoweb/core';
import React from 'react';
import { Provider } from 'react-redux';
import { getDatasetProps } from '../helpers';
import MapViewer from './map-viewer/MapViewer';

const App: React.FC = () => {
    const datasetProps = getDatasetProps(new URL(document.location.href));

    return (
        <Provider store={store}>
            <MapViewer {...datasetProps} />
        </Provider>
    );
};

export default App;
