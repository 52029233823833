import React from 'react';
import {
    generateLayerId,
    LegendConnect,
    CoreThemeStoreProvider,
    MapViewConnect,
    mapActions,
    store,
    LayerManagerConnect,
    MapControls,
    LayerManagerMapButtonConnect,
    LegendMapButtonConnect,
    publicLayers,
} from '@opengeoweb/core';
import { ErrorBoundary } from '@opengeoweb/shared'
import { LayerType, WMGetServiceFromStore, WMLayer } from '@opengeoweb/webmap';
import { useDispatch } from 'react-redux';
import { IDatasetProps } from '../../models/dataset-props';

const baseLayer: any = {
    name: 'arcGisSat',
    title: 'arcGisSat',
    type: 'twms',
    layerType: LayerType.baseLayer,
    enabled: true,
    id: generateLayerId(),
};

const MapViewer: React.FC<IDatasetProps> = ({ geospatialServerUrl, dataset, previewLayer, timestamp, elevation }: IDatasetProps) => {
    const dispatch = useDispatch();
    const mapId: any = 'map';
    const serviceUrl = `${geospatialServerUrl}?DATASET=${dataset}&`;

    const handleFlatLayerObject = (layerObjects: WMLayer[]): void => {
        // Add all layers
        layerObjects.some((layerObject) => {

            //Set default layer equal to previewLayer. Show first layer when previewLayer undefined
            if ((!layerObject.name || previewLayer !== layerObject.name) && previewLayer) {
                return false;
            }

            dispatch(mapActions.setLayers({
                layers: [
                    {
                        service: serviceUrl,
                        name: layerObject.name,
                        format: 'image/png',
                        layerType: LayerType.mapLayer,
                        enabled: true,
                        id: `dataset-${dataset}-${layerObject.name}`,
                        opacity: 0.7,
                        onReady: (layer: WMLayer) => {
                                layer.zoomToLayer();
                        },
                        dimensions: [
                            {
                                name: 'time',
                                units: 'ISO8601',
                                currentValue: timestamp,
                            },
                            {
                                name: 'elevation',
                                currentValue: elevation,
                            }
                        ],
                    } as any
                ], mapId
            }));

            dispatch(
                mapActions.setBaseLayers({
                    mapId,
                    layers: [baseLayer],
                }),
            );

            return true;
        });
    };

    const failure = (e: Error): void => {
        console.error(e);
    };

    React.useEffect(() => {
        const wmjsService = WMGetServiceFromStore(serviceUrl);
        wmjsService.getLayerObjectsFlat(
            handleFlatLayerObject as any,
            failure as any,
            false
        );
    });

    if (!dataset) {
        return <b>No dataset configured</b>;
    }

    return (
        <CoreThemeStoreProvider store={store}>
            <ErrorBoundary>
                <MapControls>
                    <LayerManagerMapButtonConnect mapId={mapId} />
                    <LegendMapButtonConnect mapId={mapId} />
                </MapControls>
                <LayerManagerConnect
                    bounds="parent"
                    showTitle
                    layerSelect={false}
                    preloadedAvailableBaseLayers={[
                        publicLayers.baseLayerArcGisCanvas,
                        publicLayers.baseLayerHeiGit,
                    ]}
                    preloadedMapServices={[]}
                    preloadedBaseServices={[]}
                />
                <LegendConnect showMapId />
                <MapViewConnect mapId={mapId} showLayerInfo={false} displayTimeInMap />
            </ErrorBoundary>
        </CoreThemeStoreProvider>
    );
};

export default MapViewer;
